'use client'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

export const SiteContainer = styled.div<{ pushDown?: boolean; small?: boolean }>`
  ${({ pushDown }) =>
    pushDown &&
    css`
      margin-top: 4rem;
    `}

  @media only screen and (max-width: 800px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media only screen and (min-width: 800px) {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  @media only screen and (min-width: 1000px) {
    padding-left: ${({ small }) => (small ? '30%' : '20%')};
    padding-right: ${({ small }) => (small ? '30%' : '20%')};
  }
`
