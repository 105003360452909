'use client'
import {SiteContainer} from '@app/site/common/components/SiteContainer'
import {BlockType} from '@common/features/blocks/Block'
import {TipTapView} from '@common/components/TipTapView'
import styled from '@emotion/styled'

const AboutContainer = styled.div`
  width: 100%;
  max-width: 50rem;
  min-width: 100%;

  margin-top: 3rem;
`

const Headline = styled.h3`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.onBackground};

  @media only screen and (min-width: 800px) {
    font-size: 1.8rem;
  }

  padding: 1rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.onBackgroundLightest};
`

const AboutContent = styled.div`
  white-space: pre-wrap;
  padding: 1rem 0;
`

export interface DeprecatedStartPageBlockProps {
  type: BlockType.DeprecatedStartPage
  title?: string
  body?: string
}

export const DeprecatedStartPageBlock = ({ title, body }: DeprecatedStartPageBlockProps) => {
  return (
    <>
      <SiteContainer small={true} style={{ paddingBottom: '10rem' }}>
        <>
          {title && body && (
            <AboutContainer>
              <Headline>{title}</Headline>
              <AboutContent>
                <TipTapView storedContent={body} />
              </AboutContent>
            </AboutContainer>
          )}
        </>
      </SiteContainer>
    </>
  )
}
