import { gql } from '@apollo/client'
import { useSlimStaticWebsiteQuery } from '@graphql/generated'

gql`
  query SlimStaticWebsite($slug: String!) {
    staticWebsite(slug: $slug) {
      id
      name
      orgNumber
      address {
        street
        city
        postalCode
      }
      startPage {
        title
        body
      }
      features {
        errands
      }
    }
  }
`

export const useSlimStaticWebsite = (slug: string) => {
  const { data, loading } = useSlimStaticWebsiteQuery({ variables: { slug }, skip: !slug })

  const name = data?.staticWebsite?.name
  const startPage = data?.staticWebsite?.startPage
  const address = data?.staticWebsite?.address
  const id = data?.staticWebsite?.id ?? ''
  const orgNumber = data?.staticWebsite?.orgNumber ?? ''
  const hasErrandsFeature = data?.staticWebsite?.features?.errands ?? false

  return {
    id,
    name,
    startPage,
    loading,
    address,
    orgNumber,
    hasErrandsFeature
  }
}
