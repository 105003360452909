import { EditorContent, useEditor } from '@tiptap/react'
import { StarterKit } from '@tiptap/starter-kit'
import React, { useEffect } from 'react'
import { Link } from '@tiptap/extension-link'
import { Image } from '@tiptap/extension-image'
import styled from '@emotion/styled'

const ReadOnlyEditor = styled.div`
  .ProseMirror p {
    margin: 0;
  }

  .ProseMirror:focus {
    outline: none;
  }

  .ProseMirror {
    > * + * {
      margin-top: 0.75em;
    }

    ul,
    ol {
      padding: 0 1rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1.1;
    }

    code {
      background-color: rgba(#616161, 0.1);
      color: #616161;
    }

    pre {
      background: #0d0d0d;
      color: #fff;
      font-family: 'JetBrainsMono', monospace;
      padding: 0.75rem 1rem;
      border-radius: 0.5rem;

      code {
        color: inherit;
        padding: 0;
        background: none;
        font-size: 0.8rem;
      }
    }

    img {
      max-width: 100%;
      height: auto;
    }

    blockquote {
      padding-left: 1rem;
      border-left: 2px solid rgba(#0d0d0d, 0.1);
    }

    hr {
      border: none;
      border-top: 2px solid rgba(#0d0d0d, 0.1);
      margin: 2rem 0;
    }
  }
`

const restoreContent = (storedContent: string) => {
  if (!storedContent) {
    return ''
  }

  try {
    return JSON.parse(storedContent)
  } catch (e) {
    return ''
  }
}

export const TipTapView: React.FC<{ storedContent: string }> = ({ storedContent }) => {
  const editor = useEditor({
    editable: false,
    extensions: [StarterKit, Link.configure({ openOnClick: true }), Image],
    content: '',
  })

  useEffect(() => {
    if (!editor) return

    editor.commands.setContent(restoreContent(storedContent))
  }, [editor, storedContent])

  return (
    <ReadOnlyEditor>
      <EditorContent editor={editor} />
    </ReadOnlyEditor>
  )
}
