'use client'
import { BlockType } from '@common/features/blocks/Block'
import { useSlug } from '@common/hooks/use-slug'
import { TipTapView } from '@common/components/TipTapView'
import styled from '@emotion/styled'
import { useStaticInfoPage } from '@app/site/common/hooks/use-static-info-page'
import { SiteContainer } from '@app/site/common/components/SiteContainer'
import { useSlimStaticWebsite } from '@app/site/common/hooks/use-slim-static-website'
import { useParams } from 'next/navigation'
import { asSingleString } from '@common/utils/as-single-string'
import Navigate from '@common/utils/Navigate'
import Head from 'next/head'

const InnerContainer = styled.div`
  width: 100%;
  max-width: 50rem;
  min-width: 100%;

  margin-top: 3rem;
`

const Headline = styled.h3`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.onBackground};

  @media only screen and (min-width: 800px) {
    font-size: 1.8rem;
  }

  padding: 1rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.onBackgroundLightest};
`

const Content = styled.div`
  white-space: pre-wrap;
  padding: 1rem 0;
`

const Breadcrumb = styled.div`
  margin-bottom: -0.25rem;

  font-size: 0.85rem;
  color: ${({ theme }) => theme.onBackgroundSemiLight};
`

export interface DeprecatedInfoPageBlockProps {
  type: BlockType.DeprecatedInfoPage
}

export const DeprecatedInfoPageBlock = () => {
  const { infoPageSlug: infoPageSlugRaw } = useParams()
  const infoPageSlug = asSingleString(infoPageSlugRaw)

  const slug = useSlug()

  const { name, loading } = useSlimStaticWebsite(slug)
  const { page } = useStaticInfoPage(slug, infoPageSlug ?? '')

  if (loading || !page) {
    return null
  }

  if (page?.body === null) {
    return <Navigate to={'/logga-in?to=info/' + infoPageSlug} replace={true} />
  }

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <title>
          {page.title} | {name}
        </title>
      </Head>
      <SiteContainer small={true} style={{ paddingBottom: '10rem' }}>
        {page?.title && page?.body && (
          <InnerContainer>
            {page?.group && <Breadcrumb>{page.group}</Breadcrumb>}
            <Headline>{page.title}</Headline>
            <Content>
              <TipTapView storedContent={page.body} />
            </Content>
          </InnerContainer>
        )}
      </SiteContainer>
    </>
  )
}
