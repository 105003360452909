import { useMemo } from 'react'
import { useParams } from 'next/navigation'

export const useSlug = () => {
  const params = useParams()

  return useMemo(() => {
    let result: string

    if (Array.isArray(params?.slug)) {
      result = params?.slug[0]
    } else {
      result = params?.slug
    }

    return result
  }, [params?.slug])
}
