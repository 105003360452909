import { gql } from '@apollo/client'
import { useStaticInfoPageBySlugQuery } from '@graphql/generated'

gql`
  query StaticInfoPageBySlug($websiteSlug: String!, $slug: String!) {
    staticInfoPageBySlug(websiteSlug: $websiteSlug, slug: $slug) {
      id
      title
      body
      group
      blocks {
        ...BlockFragment
      }
    }
  }
`

export const useStaticInfoPage = (websiteSlug: string, slug: string) => {
  const { data, loading } = useStaticInfoPageBySlugQuery({
    variables: { websiteSlug: websiteSlug ?? '', slug: slug ?? '' },
    skip: !slug || !websiteSlug,
  })

  return {
    page: data?.staticInfoPageBySlug ?? null,
    blocks: data?.staticInfoPageBySlug?.blocks ?? [],
    loading,
  }
}
